$.datepicker.setDefaults({
    dateFormat: 'dd-mm-yy'
});

$(function() {
    $( ".datepicker-future" ).datepicker({
        dateFormat: "dd/mm/yy",
        changeYear: true,
        changeMonth: true
    });

    $( ".datepicker-history" ).datepicker({
        dateFormat: "dd/mm/yy",
        changeYear: true,
        changeMonth: true,
        maxDate: "0"
    });

    $( ".datepicker" ).datepicker({
        dateFormat: "dd/mm/yy",
        changeYear: true,
        changeMonth: true,
    });

    $( ".datefrom" ).datepicker({
        defaultDate: "+1w",
        changeMonth: true,
        dateFormat: "dd/mm/yy",
        numberOfMonths: 2,
        onClose: function( selectedDate ) {
            $( ".dateto" ).datepicker( "option", "minDate", selectedDate );
        }
    });
    $( ".dateto" ).datepicker({
        defaultDate: "+1w",
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        numberOfMonths: 2,
        onClose: function( selectedDate ) {
            $( ".datefrom" ).datepicker( "option", "maxDate", selectedDate );
        }
    });

    initMonthOnlyDatepicker();
    initYearOnlyDatepicker();
});

function initYearOnlyDatepicker() {
     $('.datepicker-year-only').datepicker( {
        changeYear: true,
        showButtonPanel: true,
        dateFormat: 'yy',
        onClose: function(dateText, inst) {
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
            $(this).datepicker('setDate', new Date(year, 1, 1));
        },
        beforeShow: function(input, instance){
            instance.dpDiv.removeClass('datepicker-month-only')
            instance.dpDiv.addClass('datepicker-year-only')
        }
    });
}

function initMonthOnlyDatepicker() {
    $('.datepicker-month-only').datepicker( {
        changeMonth: true,
        showButtonPanel: true,
        dateFormat: 'MM',
        onClose: function(dateText, inst) {
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
            $(this).datepicker('setDate', new Date(1, month, 1));
        },
        beforeShow: function(input, instance){
            instance.dpDiv.removeClass('datepicker-year-only');
            instance.dpDiv.addClass('datepicker-month-only');
        }
    });
}


/**
 * Show confirm modal dialog for deletion. Will submit the form if it is confirmed by user
 * @param {html} form the form to submit
 */
function confirmDeleteModal(form) {
    swal({
        title: "Are you sure?",
        text: "Your will not be able to recover this once deleted!",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes, delete it!",
        closeOnConfirm: false
    },
        function(isConfirm) {
            if(isConfirm) {
                form.submit();
            }
        }
    );
}

/**
 * Ajax utility to call backend API
 * @param url
 * @param method
 * @returns {*}
 */
function restUtil(url, method) {
    method = method || 'get';

    return $.ajax({
        url: url,
        method: method
    }).then(function(data) {
        return data;
    });
}
